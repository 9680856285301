import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import UserExistsWithPhoneDTO from '@/models/dto/UserExistsWithPhoneDTO'

const httpService: HttpService = new HttpService()

export const checkPhone = (phone: string, companyId: number, applicationId: number): Promise<AxiosResponse<UserExistsWithPhoneDTO>> => {
  const params = new URLSearchParams({
    phone: phone,
    compId: companyId.toString(),
    applicationId: applicationId.toString()
  })
  const query = params.toString()
  const url = `https://${baseUrl()}/user/checkPhone?${query}`

  return httpService.get(url)
}
